.max-size {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bg {
	background-image: url("https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1653&q=80");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;

	@extend .max-size;
}

.overlay {
	background-color: rgba(38, 43, 72, 0.5);

	@extend .max-size;
}

.container {
	position: absolute;
	top: calc(50% - 290px);
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	width: 800px;
	height: 480px;
	margin: 0 auto;
	@media (max-width: 991px) {
		width: 720px;
	}
	@media (max-width: 768px) {
		width: 500px;
	}
}

.left-portal {
	display: flex;
	align-items: center;
	width: calc(100% - 340px);
	height: 100%;
	padding: 12px 32px;
    color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
	background-color: rgba(38, 43, 72, 0.92);
	box-shadow: 0px 3px 6px #1d2030;
	img {
		margin: 18px auto;
	}

	p {
		font-size: 14px;
	}

	button {
		margin: 32px 0;
	}
}

.full-portal {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	padding: 12px 32px;
	color: #fff;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: rgba(38, 43, 72, 0.92);
	box-shadow: 0px 3px 6px #1d2030;
	img {
		margin: 18px auto;
	}

	p {
		font-size: 14px;
	}

	button {
		margin: 32px 0;
	}
}

.right-portal {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 340px;
	height: 100%;
	padding: 60px 30px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
	background-color: rgba(238, 238, 238, 0.96);
	box-shadow: 0px 2px 6px 0px #1d2030;
}

.title {
	margin: 30px 0;

	h3 {
		font-size: 24px;
		font-weight: 700 !important;
		margin-bottom: 12px;
	}

	span {
		font-size: 12px;
		color: white;
	}
}

.idp-button {
	width: 100%;
	border-radius: 5px;
	background-color: #0078d4;
	img {
		height: 18px;
		margin: 0;
		margin-right: 6px;
	}
}

.line-input {
	font-size: 13px;
	width: 100%;
	margin-bottom: 12px;
	padding: 12px 0;
	transition: border ease-in-out 0.4s;
	border: 0;
	border-bottom: 2px solid #ccc;
	outline: none;
	background-color: transparent;
	&:focus {
		border-bottom: 2px solid #0078d4;
	}
}

.forget-password {
	margin: 0 0 12px;
	text-align: right;
	a {
		font-size: 13px;
		color: grey;
	}
}

.btn-login {
	width: 100%;
	margin: 12px 0;
	border-radius: 5px;
}

.legacy-login {
	font-size: 10px;
	text-decoration: none;
	opacity: 0.8;
}
